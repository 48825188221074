var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { width: "400" } },
    [
      _c("v-card-title", [_vm._v(" Step-by-Step Token Creation ")]),
      _c("v-divider"),
      _c("v-card-text", [
        _c("p", [
          _c("strong", [_vm._v("Step 1:")]),
          _vm._v(
            " Sign in to your 'FAIR Data Services' account, where you should have a 'FDSA Data Steward' use role. Then access your user menu and choose 'My API tokens'. "
          ),
        ]),
        _c("p", [
          _c("strong", [_vm._v("Step 2:")]),
          _vm._v(" Navigate to the top menu and select 'New'. "),
        ]),
        _c("p", [
          _c("strong", [_vm._v("Step 3:")]),
          _vm._v(
            " Complete the provided form. Under the 'Role' field, select 'Request Approval' option. "
          ),
        ]),
        _c("p", [
          _c("strong", [_vm._v("Step 4:")]),
          _vm._v(
            " Once done, click 'Create'. Copy the generated token and paste it into the designated area here. "
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }