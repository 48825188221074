<template>
    <v-dialog v-model="tokenModal" persistent width="500"> 
        <v-card>
            <v-card-title>
                <span>Update Token</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-form>
                    <label class="label-form">FAIR Data Steward Token</label>
                    <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-x>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon color="green" small v-bind="attrs" v-on="on">
                            <v-icon small>mdi-information</v-icon>
                          </v-btn>
                        </template>
                        <DatasetInstructions/>
                      </v-menu>
                    <textarea class="token" cols="30" rows="8" v-model="callback_token"></textarea>
                </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="cancel" @click="close">Cancel</v-btn>
                <v-btn color="confirm" @click="updateToken" dark>Update</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import DatasetInstructions from "@/components/dialogs/DatasetInstructions";
export default{
    name: "EditToken",
    components: {
        DatasetInstructions,
    },
    props: {
        openModal: Boolean,
    },
    data() {
        return {
            callback_token: '',
            tokenModal: false,
        };
    },
    created() {
        this.tokenModal = this.openModal;
    },
    methods: {
        close() {
            this.$emit("close");
        },
        updateToken() {
            this.$emit("save-token", this.callback_token);
        },
    },
};
</script>
<style>
.token {
    background-color: #fff;
    display: block;
    width: 100%;
    border: 1px solid #aba9a9;
    padding: 8px;
    border-radius: 5px;
    margin-bottom:6px;
  }
</style>