var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isIdle ? _c("ModalIdleVue") : _vm._e(),
      _c(
        "v-tooltip",
        {
          attrs: { left: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "button",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "image-button",
                          on: {
                            click: function ($event) {
                              _vm.support = true
                            },
                          },
                        },
                        "button",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("v-img", {
                        attrs: {
                          width: "60",
                          src: require("@/assets/support.svg"),
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [_c("span", [_vm._v("Support")])]
      ),
      _vm.$store.state.keycloak && _vm.allowed ? _c("LicenseBox") : _vm._e(),
      _c(
        "v-tabs",
        {
          attrs: { "background-color": "#e9f2f9" },
          model: {
            value: _vm.mainTab,
            callback: function ($$v) {
              _vm.mainTab = $$v
            },
            expression: "mainTab",
          },
        },
        [
          _vm.allowed
            ? _c("v-tab", { staticClass: "tab" }, [_vm._v("Users")])
            : _vm._e(),
          _c("v-tab", { staticClass: "tab" }, [_vm._v("Data Access Requests")]),
          _vm.activeUserRole !== "requester"
            ? _c("v-tab", { staticClass: "tab" }, [_vm._v("Tasks")])
            : _vm._e(),
          _vm.allowed
            ? _c("v-tab", { staticClass: "tab" }, [_vm._v("Datasources")])
            : _vm._e(),
          _vm.allowed
            ? _c("v-tab", { staticClass: "tab" }, [_vm._v("Registries")])
            : _vm._e(),
          _vm.allowed
            ? _c("v-tab", { staticClass: "tab" }, [_vm._v("Settings")])
            : _vm._e(),
          _vm.allowed
            ? _c("v-tab", { staticClass: "tab" }, [_vm._v("Service Info")])
            : _vm._e(),
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function (ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "ml-auto mr-5",
                              attrs: { rounded: "", icon: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.$store.dispatch("logOut")
                                },
                              },
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c("v-icon", { attrs: { color: "primary" } }, [
                            _vm._v("mdi-logout"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [_c("span", [_vm._v("Logout")])]
          ),
        ],
        1
      ),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.mainTab,
            callback: function ($$v) {
              _vm.mainTab = $$v
            },
            expression: "mainTab",
          },
        },
        [
          _vm.allowed
            ? _c(
                "v-tab-item",
                [_c("usersSection", { attrs: { rules: _vm.rules } })],
                1
              )
            : _vm._e(),
          _c(
            "v-tab-item",
            [
              _c("darSection", {
                key: _vm.componentKey,
                attrs: { rules: _vm.rules, allowed: _vm.allowed },
              }),
            ],
            1
          ),
          _vm.activeUserRole !== "requester"
            ? _c(
                "v-tab-item",
                [
                  _c("tasksSection", {
                    attrs: { rules: _vm.rules, allowed: _vm.allowed },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.allowed
            ? _c(
                "v-tab-item",
                [
                  _c("datasourceSection", {
                    key: _vm.dataKey,
                    attrs: { rules: _vm.rules },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.allowed
            ? _c(
                "v-tab-item",
                [_c("registrySection", { attrs: { rules: _vm.rules } })],
                1
              )
            : _vm._e(),
          _vm.allowed
            ? _c(
                "v-tab-item",
                [_c("settingSection", { attrs: { rules: _vm.rules } })],
                1
              )
            : _vm._e(),
          _vm.allowed
            ? _c(
                "v-tab-item",
                [_c("serviceSection", { attrs: { rules: _vm.rules } })],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.support
        ? _c("SupportEmailModal", {
            attrs: { openModal: _vm.support, rules: _vm.rules },
            on: { "close-support": _vm.closeModal },
          })
        : _vm._e(),
      _c("Footer", { attrs: { rules: _vm.rules } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }