var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", width: "500" },
      model: {
        value: _vm.updateReg,
        callback: function ($$v) {
          _vm.updateReg = $$v
        },
        expression: "updateReg",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-icon",
                { staticClass: "mr-3", attrs: { color: "primary" } },
                [_vm._v("mdi-pencil")]
              ),
              _vm._v(" Update Registry "),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-form",
            { ref: "editRegForm", attrs: { "lazy-validation": "" } },
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "label",
                    { staticClass: "label-form", attrs: { for: "name" } },
                    [_vm._v("Registry Name")]
                  ),
                  _c("v-text-field", {
                    attrs: {
                      solo: "",
                      dense: "",
                      outlined: "",
                      id: "name",
                      rules: [_vm.rules.required],
                    },
                    model: {
                      value: _vm.editReg.friendly_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.editReg, "friendly_name", $$v)
                      },
                      expression: "editReg.friendly_name",
                    },
                  }),
                  _c(
                    "label",
                    { staticClass: "label-form", attrs: { for: "address" } },
                    [_vm._v("Registry Address")]
                  ),
                  _c("v-text-field", {
                    attrs: {
                      solo: "",
                      dense: "",
                      outlined: "",
                      id: "address",
                      rules: [_vm.rules.required],
                    },
                    model: {
                      value: _vm.editReg.registry_address,
                      callback: function ($$v) {
                        _vm.$set(_vm.editReg, "registry_address", $$v)
                      },
                      expression: "editReg.registry_address",
                    },
                  }),
                  _c(
                    "label",
                    { staticClass: "label-form", attrs: { for: "username" } },
                    [_vm._v("Registry Username")]
                  ),
                  _c("v-text-field", {
                    attrs: {
                      solo: "",
                      dense: "",
                      outlined: "",
                      id: "username",
                      rules: [_vm.rules.required],
                    },
                    model: {
                      value: _vm.editReg.registry_username,
                      callback: function ($$v) {
                        _vm.$set(_vm.editReg, "registry_username", $$v)
                      },
                      expression: "editReg.registry_username",
                    },
                  }),
                  _c(
                    "label",
                    { staticClass: "label-form", attrs: { for: "pass" } },
                    [_vm._v("Registry Password")]
                  ),
                  _c("v-text-field", {
                    attrs: {
                      solo: "",
                      dense: "",
                      outlined: "",
                      autocomplete: "current-password",
                      id: "pass",
                      "append-icon": _vm.showPass ? "mdi-eye" : "mdi-eye-off",
                      type: _vm.showPass ? "text" : "password",
                      rules: [_vm.rules.required],
                    },
                    on: {
                      "click:append": function ($event) {
                        _vm.showPass = !_vm.showPass
                      },
                    },
                    model: {
                      value: _vm.editReg.registry_password,
                      callback: function ($$v) {
                        _vm.$set(_vm.editReg, "registry_password", $$v)
                      },
                      expression: "editReg.registry_password",
                    },
                  }),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { attrs: { color: "cancel" }, on: { click: _vm.close } },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "confirm", dark: "" },
                      on: { click: _vm.update },
                    },
                    [_vm._v("update")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }