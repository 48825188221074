<template>
  <v-card class="px-5 pt-5">
    <v-card-text class="px-10">
      <div>
        <v-data-table
          class="elevation-6 mt-10 mb-10"
          :headers="dataSourceHeaders"
          :items="dataSourceList"
          :header-props="{ sortIcon: null }"
          :items-per-page.sync="pagination.rowsPerPage"
          :page.sync="pagination.page"
          @update:page="onPageChange"
          @update:items-per-page="onItemsPerPageChange"
          :footer-props="{
            itemsPerPageOptions: [5, 15, 25, 50],
          }"
          :disable-sort="true"
          :server-items-length="totalItems"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.datasource_id }}</td>
              <td>{{ item.database_type }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.description }}</td>
              <td>{{ item.host }}</td>
              <td>{{ item.port }}</td>
              <td>{{ item.database_name }}</td>
              <td>{{ item.database_user }}</td>
              <td>
                <v-menu transition="slide-x-transition" bottom right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                  </template>
                  <v-list>
                    <v-list-item @click="checkDataSource(item)">
                      <v-list-item-icon>
                        <v-icon>mdi-database-eye</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Check</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="addDataset(item.datasource_id)">
                      <v-list-item-icon>
                        <v-icon>mdi-database-plus</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Add Dataset</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="getDatasets(item.datasource_id)">
                      <v-list-item-icon>
                        <v-icon>mdi-database-search</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Show Datasets</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="editDataSource(item)">
                      <v-list-item-icon>
                        <v-icon>mdi-database-edit</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Update</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="deleteDataSource(item)">
                      <v-list-item-icon>
                        <v-icon color="error">mdi-database-minus</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="delete-option"
                        >Delete</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
          <template v-slot:footer>
            <v-btn
              class="mt-5"
              text
              color="primary"
              small
              @click="newDataSourcePopup = true"
              ><v-icon>mdi-plus</v-icon> Add a new Data Source</v-btn
            >
          </template>
        </v-data-table>

        <!-- Add datasource modal -->
        <AddDatasourceModal
          @new-ds="createNewDatasource"
          @close-ds="closeNewDsModal"
          :rules="rules"
          :openModal="newDataSourcePopup"
          v-if="newDataSourcePopup"
        />

        <!-- Update datasource modal -->
        <UpdateDatasourceModal
          :editDs="editableDs"
          :openModal="updateDataSourcePopup"
          :rules="rules"
          v-if="updateDataSourcePopup"
          @update="updateDataSource"
          @close-edit="closeUpdateDatasource"
        />

        <!-- Add dataset to a datasource modal -->
        <AddDatasetModal
          @new-ds="saveNewDataset"
          @close-new-ds="closeAddDataset"
          :openModal="addDatasetPopup"
          :id="dtId"
          :webHook="webHook"
          :schemas="schemas"
          v-if="addDatasetPopup"
          :rules="rules"
        />

        <!-- Show datasets modal -->
        <ShowDatasetsModal
          :datasets="datasetsById"
          :datasourceId="datasourceId"
          :openModal="datasetsByIdPopup"
          :datasetPagination="datasetPagination"
          :datasetTotal="totalDatasets"
          v-if="datasetsByIdPopup"
          @dts-privacy="changeDatasetPrivacy"
          @dts-level="changeDsLevel"
          @refresh="getDatasets"
          @close-show-dts="closeDatasetsList"
          @delete="deleteDataset"
          @newCatalogue="addCatalogue"
          @update-catalogue="updateCatalogue"
          @track-db="trackAll"
          @new-dataset="addDataset"
          @update-token="updateToken"
          @manage-auto-approve="manageAutoApprove"
          :rules="rules"
          :webHook="webHook"
        />
        <!-- Track all loader -->
        <TrackLoader v-if="trackLoader" />
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import axios from "axios";
import swal from "sweetalert";
import AddDatasourceModal from "@/components/dialogs/AddDatasourceModal.vue";
import UpdateDatasourceModal from "@/components/dialogs/UpdateDatasourceModal.vue";
import AddDatasetModal from "@/components/dialogs/AddDatasetModal.vue";
import ShowDatasetsModal from "@/components/dialogs/ShowDatasetsModal.vue";
import TrackLoader from "@/components/dialogs/TrackLoader.vue";
export default {
  name: "DatasourceSection",
  components: {
    AddDatasourceModal,
    UpdateDatasourceModal,
    AddDatasetModal,
    ShowDatasetsModal,
    TrackLoader,
  },
  props: {
    admin_token: String,
    rules: Object,
  },
  data() {
    return {
      dataSourceHeaders: [
        { text: "datasource ID" },
        { text: "Database Type" },
        { text: "Name" },
        { text: "Description" },
        { text: "Host" },
        { text: "Port" },
        { text: "Database Name" },
        { text: "Database User" },
        { text: "Actions" },
      ],
      editableDs: {},
      dataSourceList: [],
      newDataSourcePopup: false,
      updateDataSourcePopup: false,
      datasetsById: [],
      addDatasetPopup: false,
      datasetsByIdPopup: false,
      dtId: null,
      datasourceId: null,
      trackLoader: false,
      schemas: [],
      pagination: {
        page: 1,
        rowsPerPage: 5,
      },
      datasetPagination: {
        page: 1,
        rowsPerPage: 5,
      },
      totalItems: 0,
      totalDatasets: 0,
      webHook: [],
    };
  },
  methods: {
    async getDatasources() {
      try {
        this.dataSourceList = [];
        const res = await axios.get(
          `/api/admin/v1.0/datasources?page=${this.pagination.page}&page_size=${this.pagination.rowsPerPage}`
        );
        this.dataSourceList = res.data.data;
        this.totalItems = res.data.total;
      } catch (err) {
        console.log(err);
      }
    },
    async getWebHook() {
      const wh = axios.create({
        baseURL: `https://${process.env.VUE_APP_FQDN}`,
      })
      try {
        const res = await wh.get("/api/admin/v1.0/web_hook");
        if (Object.keys(res.data).length > 0) {
          this.webHook.push(res.data)
        } else {
          this.webHook = []
        }
      } catch (err) {
        console.log(err);
      }
    },
    async addCatalogue(cat, dtID, id) {
      this.datasetsByIdPopup = false;
      this.$store.commit("setLoader", true);
      try {
        const res = await axios.post(
          `/api/admin/v1.0/datasets/${dtID}/catalogue`,
          cat
        );
        swal("Confirmation", "Catalogue added", "success");
        console.log(res.status);
      } catch (err) {
        console.log(err);
      } finally {
        this.$store.commit("setLoader", false);
        this.getDatasets(id);
      }
    },
    async updateCatalogue(cat, dtID, id) {
      this.datasetsByIdPopup = false;
      this.$store.commit("setLoader", true);
      try {
        const res = await axios.patch(
          `/api/admin/v1.0/datasets/${dtID}/catalogue`,
          cat
        );
        swal("Confirmation", "Catalogue updated", "success");
        console.log(res.status);
      } catch (err) {
        console.log(err);
      } finally {
        this.$store.commit("setLoader", false);
        this.getDatasets(id);
      }
    },
    async createNewDatasource(dts) {
      this.$store.commit("setLoader", true);
      try {
        const res = await axios.post("/api/admin/v1.0/datasources", dts);
        this.newDataSourcePopup = false;
        console.log(res.status);
      } catch (err) {
        console.log(err);
        this.newDataSourcePopup = false;
      } finally {
        this.getDatasources();
        this.$store.commit("setLoader", false);
      }
    },
    deleteDataSource(dts) {
      swal({
        title: "Delete",
        text: "Are you sure you want to delete this Data source?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          this.$store.commit("setLoader", true);
          try {
            const res = await axios.delete(
              `/api/admin/v1.0/datasources/${dts.datasource_id}`
            );
            console.log(res.status);
            swal("Confirmation", "Datasource deleted", "success");
            this.getDatasources();
          } catch (err) {
            console.log(err);
          } finally {
            this.$store.commit("setLoader", false);
          }
        }
      });
    },
    async manageAutoApprove(val, dataset, datasourceId) {
      if(val){
        swal({
          title: "Warning",
          text: "If enabled, all DARs associated with this dataset will be automatically approved.",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then(async (willDelete) => {
          if (willDelete) {
            this.$store.commit("setLoader", true);
            try {
              await axios.patch(
                `/api/admin/v1.0/datasets/${dataset.dataset_id}`,
                { auto_approve_dars: val }
              );
              swal("Confirmation", "Auto approve updated", "success");
            } catch (err) {
              console.log(err);
            } finally {
              this.$store.commit("setLoader", false);
              this.getDatasets(datasourceId);
            }
          }
        });
      } else {
        this.$store.commit("setLoader", true);
        try {
          await axios.patch(
            `/api/admin/v1.0/datasets/${dataset.dataset_id}`,
            { auto_approve_dars: val }
          );
          swal("Confirmation", "Auto approve updated", "success");
        } catch (err) {
          console.log(err);
        } finally {
          this.$store.commit("setLoader", false);
          this.getDatasets(datasourceId);
        }
      }
    },
    async checkDataSource(dts) {
      this.$store.commit("setLoader", true);
      try {
        const res = await axios.get(
          `/api/admin/v1.0/datasources/${dts.datasource_id}/check`
        );
        swal("Status", res.data.message, "success");
      } catch (err) {
        console.log(err);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    editDataSource(dts) {
      this.editableDs = Object.assign({}, dts);
      this.updateDataSourcePopup = true;
    },
    async updateDataSource(ds) {
      this.$store.commit("setLoader", true);
      this.updateDataSourcePopup = false;
      let id = ds.datasource_id;
      delete ds["datasource_id"];
      delete ds["schemas"];

      try {
        const res = await axios.patch(`/api/admin/v1.0/datasources/${id}`, ds);
        console.log(res.status);
        swal("Confirmation", "Datasource updated", "success");
        this.getDatasources();
        this.updateDataSourcePopup = false;
      } catch (err) {
        console.log(err);
        this.updateDataSourcePopup = false;
      }
      this.$store.commit("setLoader", false);
    },
    addDataset(dts) {
      this.dtId = dts;
      const dtsIDTarget = this.dataSourceList.find(
        (obj) => obj.datasource_id === dts
      );
      this.schemas = dtsIDTarget.schemas;
      this.addDatasetPopup = true;
    },
    async trackAll(id) {
      this.trackLoader = true;
      try {
        const data = await axios.get(
          `/api/admin/v1.0/datasets/${id}/track_all`
        );
        swal("Confirmation", "Dataset tracked successfully!", "success");
        console.log(data);
      } catch (err) {
        console.log(err);
      } finally {
        this.trackLoader = false;
      }
    },
    async updateToken(dataset, datasourceId, token) {
      this.datasetsByIdPopup = false;
      this.$store.commit("setLoader", true);
      try {
        await axios.patch(
          `/api/admin/v1.0/datasets/${dataset.dataset_id}`,
          { callback_token: token }
        );
        swal("Confirmation", "Token updated", "success");
      } catch (err) {
        console.log(err);
      } finally {
        this.$store.commit("setLoader", false);
        this.getDatasets(datasourceId);
      }
    },
    async saveNewDataset(dts) {
      if (this.datasetsByIdPopup) this.datasetsByIdPopup = false;
      this.$store.commit("setLoader", true);
      this.addDatasetPopup = false;
      try {
        const res = await axios.post("/api/admin/v1.0/datasets", dts);
        this.trackAll(res.data.dataset_id);
        this.getDatasets(res.data.datasource_id);
      } catch (err) {
        console.log(err);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    async getDatasets(dts, pagination) {
      if (pagination) {
        this.datasetPagination = pagination;
      }

      if (this.datasetsByIdPopup) this.datasetsByIdPopup = false;
      this.datasetsById = [];
      try {
        const res = await axios.get(
          `/api/admin/v1.0/datasets?page=${this.datasetPagination.page}&page_size=${this.datasetPagination.rowsPerPage}`,
          {
            params: { datasource_id: dts },
          }
        );
        this.datasetsById = res.data.data;
        this.totalDatasets = res.data.total;
        this.datasourceId = dts;
        this.datasetsByIdPopup = true;
      } catch (err) {
        console.log(err);
      }
    },
    deleteDataset(dts, datasourceId) {
      swal({
        title: "Delete",
        text: "Are you sure you want to delete this dataset?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          this.datasetsByIdPopup = false;
          this.$store.commit("setLoader", true);
          try {
            const res = await axios.delete(
              `/api/admin/v1.0/datasets/${dts.dataset_id}`
            );
            console.log(res.status);
          } catch (err) {
            console.log(err);
          } finally {
            this.getDatasets(datasourceId);
            this.$store.commit("setLoader", false);
          }
        }
      });
    },
    async changeDatasetPrivacy(dataset, datasourceId) {
      if (!dataset.trusted) {
        swal({
          title: "Warning",
          text: "Please verify that you intend to allow non-quarantined tasks on this dataset. With this setting, it's technically possible that researcher models may inadvertently leak record-level data, which will not be reviewed. Please click OK to allow this setting",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then(async (willDelete) => {
          this.datasetsByIdPopup = false;
          if (willDelete) {
            this.$store.commit("setLoader", true);
            try {
              const res = await axios.patch(
                `/api/admin/v1.0/datasets/${dataset.dataset_id}`,
                { trusted: true }
              );
              console.log(res.status);
              swal("Confirmation", "Dataset updated", "success");
            } catch (err) {
              console.log(err);
            } finally {
              this.$store.commit("setLoader", false);
              this.getDatasets(datasourceId);
            }
          }
        });
      } else {
        this.$store.commit("setLoader", true);
        this.datasetsByIdPopup = false;
        try {
          const res = await axios.patch(
            `/api/admin/v1.0/datasets/${dataset.dataset_id}`,
            { trusted: false }
          );
          console.log(res.status);
          swal("Confirmation", "Dataset updated", "success");
        } catch (err) {
          console.log(err);
        } finally {
          this.$store.commit("setLoader", false);
          this.getDatasets(datasourceId);
        }
      }
    },
    async changeDsLevel(dataset, datasourceId, warnings) {
      swal({
        title: "Warning",
        content: {
          element: "div",
          attributes: {
            className: "message-warning",
            innerHTML: dataset.dataset_level === "level1" ? warnings.level1 : warnings.level2,
          },
        },
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        this.datasetsByIdPopup = false;
        const level = dataset.dataset_level === "level1" ? "level2" : "level1";
        if (willDelete) {
          this.$store.commit("setLoader", true);
          try {
            const res = await axios.patch(
              `/api/admin/v1.0/datasets/${dataset.dataset_id}`,
              { dataset_level: level }
            );
            console.log(res.status);
            swal("Confirmation", "Dataset updated", "success");
          } catch (err) {
            console.log(err);
          } finally {
            this.$store.commit("setLoader", false);
            this.getDatasets(datasourceId);
          }
        }
      });
    },
    onPageChange(page) {
      this.pagination.page = page;
      this.getDatasources();
    },
    onItemsPerPageChange(rowsPerPage) {
      this.pagination.rowsPerPage = rowsPerPage;
      this.getDatasources();
    },
    closeNewDsModal(val) {
      this.newDataSourcePopup = val;
    },
    closeUpdateDatasource(val) {
      this.updateDataSourcePopup = val;
    },
    closeAddDataset(val) {
      this.addDatasetPopup = val;
    },
    closeDatasetsList(val) {
      this.datasetsByIdPopup = val;
    },
  },

  mounted() {
    this.getDatasources();
    this.getWebHook();
  },
};
</script>
<style>
.delete-option {
  color: #850f19;
}
.loading-text {
  font-weight: 700;
  font-size: 20px;
}
.message-warning{
  font-size: 16px;
  position: relative;
  float: none;
  line-height: normal;
  vertical-align: top;
  text-align: left;
  display: inline-block;
  margin: 0;
  padding: 0 10px;
  font-weight: 400;
  color: rgba(0,0,0,.64);
  max-width: calc(100% - 20px);
  overflow-wrap: break-word;
  box-sizing: border-box;
  text-align: center;
}
</style>
