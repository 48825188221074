var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "px-5 pt-5" },
    [
      _c(
        "v-card-text",
        { staticClass: "px-10" },
        [
          _c(
            "div",
            [
              _c("v-data-table", {
                staticClass: "elevation-6 mt-10 mb-3",
                attrs: {
                  items: _vm.registryList,
                  headers: _vm.registryTbHeaders,
                  "header-props": { sortIcon: null },
                  "items-per-page": _vm.pagination.rowsPerPage,
                  page: _vm.pagination.page,
                  "footer-props": {
                    itemsPerPageOptions: [5, 15, 25, 50],
                  },
                  "disable-sort": true,
                  "server-items-length": _vm.totalItems,
                },
                on: {
                  "update:itemsPerPage": function ($event) {
                    return _vm.$set(_vm.pagination, "rowsPerPage", $event)
                  },
                  "update:items-per-page": [
                    function ($event) {
                      return _vm.$set(_vm.pagination, "rowsPerPage", $event)
                    },
                    _vm.onItemsPerPageChange,
                  ],
                  "update:page": [
                    function ($event) {
                      return _vm.$set(_vm.pagination, "page", $event)
                    },
                    _vm.onPageChange,
                  ],
                },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c("tr", [
                          _c("td", [_vm._v(_vm._s(item.friendly_name))]),
                          _c("td", [_vm._v(_vm._s(item.address))]),
                          _c("td", [_vm._v(_vm._s(item.username))]),
                          _c("td", [_vm._v(_vm._s(item.created_at))]),
                          _c("td", [_vm._v(_vm._s(item.updated_at))]),
                          _c(
                            "td",
                            [
                              !item.protected
                                ? _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        transition: "slide-x-transition",
                                        bottom: "",
                                        right: "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    _vm._b(
                                                      {},
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [_vm._v("mdi-dots-vertical")]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c(
                                        "v-list",
                                        [
                                          _c(
                                            "v-list-item",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.updateRegistry(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item-icon",
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-pencil"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c("v-list-item-title", [
                                                _vm._v("Update"),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list",
                                        [
                                          _c(
                                            "v-list-item",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteRegistry(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item-icon",
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { color: "error" },
                                                    },
                                                    [_vm._v("mdi-delete")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-title",
                                                { staticClass: "delete-icon" },
                                                [_vm._v("Delete")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "span",
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass: "mr-3",
                                                            attrs: {
                                                              color: "green",
                                                            },
                                                          },
                                                          "v-icon",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [_vm._v("mdi-lock")]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [_c("span", [_vm._v("Protected")])]
                                      ),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "v-btn",
                          {
                            staticClass: "mt-5",
                            attrs: { text: "", color: "primary", small: "" },
                            on: {
                              click: function ($event) {
                                _vm.newRegistryPopup = true
                              },
                            },
                          },
                          [
                            _c("v-icon", [_vm._v("mdi-plus")]),
                            _vm._v(" Add a new registry"),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("p", { staticClass: "reg-message" }, [
                _vm._v("- Registry credential must be Read/Write"),
              ]),
            ],
            1
          ),
          _vm.newRegistryPopup
            ? _c("AddRegistryModal", {
                attrs: { openModal: _vm.newRegistryPopup },
                on: {
                  "new-reg": _vm.addNewRegistry,
                  "close-reg": _vm.closeRegistryModal,
                },
              })
            : _vm._e(),
          _vm.editRegModal
            ? _c("UpdateRegistryModal", {
                attrs: {
                  reg: _vm.editableReg,
                  rules: _vm.rules,
                  openModal: _vm.editRegModal,
                },
                on: {
                  "update-reg": _vm.confirmedRegUpdate,
                  "close-reg": _vm.closeEditRegistryModal,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }