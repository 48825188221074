var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", width: "500" },
      model: {
        value: _vm.tokenModal,
        callback: function ($$v) {
          _vm.tokenModal = $$v
        },
        expression: "tokenModal",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_c("span", [_vm._v("Update Token")])]),
          _c("v-divider"),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                [
                  _c("label", { staticClass: "label-form" }, [
                    _vm._v("FAIR Data Steward Token"),
                  ]),
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "close-on-content-click": false,
                        "nudge-width": 200,
                        "offset-x": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        icon: "",
                                        color: "green",
                                        small: "",
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("mdi-information"),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.menu,
                        callback: function ($$v) {
                          _vm.menu = $$v
                        },
                        expression: "menu",
                      },
                    },
                    [_c("DatasetInstructions")],
                    1
                  ),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.callback_token,
                        expression: "callback_token",
                      },
                    ],
                    staticClass: "token",
                    attrs: { cols: "30", rows: "8" },
                    domProps: { value: _vm.callback_token },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.callback_token = $event.target.value
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { color: "cancel" }, on: { click: _vm.close } },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "confirm", dark: "" },
                  on: { click: _vm.updateToken },
                },
                [_vm._v("Update")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }