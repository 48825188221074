<template>
  <v-dialog v-model="addDtsModal" width="500" persistent>
    <v-card color="#efefef">
      <v-card-title>
        <span>
          Add Dataset
          <v-icon class="ml-3" color="primary">mdi-database-plus</v-icon>
        </span>
      </v-card-title>
      <v-divider></v-divider>
      <v-form lazy-validation ref="addDatasetForm">
        <v-card-text>
          <label class="label-form">Title (friendly name)</label>
          <v-text-field solo outlined dense v-model="dataset.title" :rules="[rules.required]"></v-text-field>
          <span></span>
          <label class="label-form">Schema name</label>
          <v-select solo outlined dense :items="schemas" v-model="dataset.schema_name"
            :rules="[rules.required]"></v-select>
          <label class="label-form">Dataset Access Level</label>
          <v-tooltip right color="success">
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="ml-1" color="success" small v-bind="attrs" v-on="on">mdi-information</v-icon>
            </template>
            <span v-html="levelInfo"></span>
          </v-tooltip>
          <v-select solo outlined dense :items="levels" item-text="title" item-value="value"
            v-model="dataset.dataset_level" :rules="[rules.required]"></v-select>
          <label class="label-form">FAIR Data Steward Token</label>
          <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon color="green" small v-bind="attrs" v-on="on">
                <v-icon small>mdi-information</v-icon>
              </v-btn>
            </template>
            <DatasetInstructions/>
          </v-menu>
          <v-textarea outlined background-color="#fff" rows="5" v-model="dataset.callback_token" :rules="[rules.required]"></v-textarea>
          <div v-if="webHook.length===0">
            <v-switch class="mt-0" v-model="autoApprove" label="Automatic Data Access Requests approval"></v-switch>
          </div>
          <v-checkbox class="mt-0" v-model="dataset.trusted" v-if="dataset.dataset_level === 'level2'"
            label="This is a synthetic/test/trusted DB, do not quarantine tasks">
          </v-checkbox>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="cancel" @click="close">Cancel</v-btn>
          <v-btn color="confirm" @click="addNewDataset" dark>Add</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import swal from "sweetalert";
import DatasetInstructions from "@/components/dialogs/DatasetInstructions";
export default {
  name: "AddDatasetModal",
  components: {
    DatasetInstructions,
  },
  props: {
    openModal: Boolean,
    rules: Object,
    webHook: Array,
    schemas: Array,
    id: Number,
  },
  data() {
    return {
      addDtsModal: false,
      dataset: {},
      menu: false,
      autoApprove: false,
      levels: [
        {
          title: "Remote query (distributed) - Level 1",
          value: "level1",
        },
        {
          title: "Federated query - Level 2",
          value: "level2",
        },
      ],
      levelInfo: `<strong>Level 1</strong> <br>
                  <p>Remote query: researcher granted access to record level data via remote query</p>
                  <strong>Level 2</strong> <br>
                  <p>Federated query: researcher granted access to perform remote computation<br>with
                    return of aggregate results, subject to quarantine
                  </p>`,
    };
  },

  created() {
    this.addDtsModal = this.openModal;
  },

  methods: {
    addNewDataset() {
      if (!this.$refs.addDatasetForm.validate()) {
        return;
      }
      this.dataset.datasource_id = this.id;
      this.dataset.auto_approve_dars = this.autoApprove;
      if (this.dataset.trusted) {
        swal({
          title: "Warning",
          text: "Please verify that you intend to allow non-quarantined tasks on this dataset. With this setting, it's technically possible that researcher models may inadvertently leak record-level data, which will not be reviewed. Please click OK to allow this setting",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then(async (willDelete) => {
          if (willDelete) {
            this.$emit("new-ds", this.dataset);
          }
        });
      } else {
        this.$emit("new-ds", this.dataset);
      }
    },
    close() {
      this.addDtsModal = false;
      this.$emit("close-new-ds", this.addDtsModal);
    },
  },
  watch: {
    addDtsModal: function (val) {
      if (!val) {
        this.$refs.addDatasetForm.reset();
      }
    },
    autoApprove: function (val) {
     if(val){
      swal({
        title: "Warning",
        text: "If enabled, all DARs associated with this dataset will be automatically approved.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          this.dataset.auto_approve = val;
        }else{
          this.autoApprove = false;
        }
      });
     }
    },
  },
};
</script>

<style scoped>
.v-input--checkbox .v-label {
  font-size: 14px;
}

.token {
  background-color: #fff;
  display: block;
  width: 100%;
  border: 1px solid #aba9a9;
  padding: 8px;
  border-radius: 5px;
  margin-bottom:6px;
}
</style>
