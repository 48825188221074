<template>
  <v-dialog v-model="datasetsModal" width="1250" persistent>
    <v-card>
      <v-card-title>
        <span>Datasets </span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div class="datasets-container">
          <v-data-table
            class="mt-3"
            :items="datasetList"
            :headers="datasetHeaders"
            :items-per-page.sync="pagination.rowsPerPage"
            :page.sync="pagination.page"
            :server-items-length="totalItems"
            @update:page="onPageChange"
            @update:items-per-page="onItemsPerPageChange"
            :footer-props="{
              itemsPerPageOptions: [5, 10, 15, 20, 25],
            }"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.dataset_id }}</td>
                <td>
                  <span>{{ item.dataset_uuid }}</span>
                  <v-icon
                    small
                    class="ml-2 copy-icon"
                    color="primary"
                    @click="copyUuid(item.dataset_uuid)"
                    >mdi-content-copy</v-icon
                  >
                </td>
                <td>{{ item.title }}</td>
                <td>{{ item.schema_name }}</td>
                <td class="text-center">
                  <v-icon v-if="item.catalogue !== null" color="green"
                    >mdi-check-circle</v-icon
                  >
                  <v-icon v-else color="gray">mdi-close-circle</v-icon>
                </td>
                <td class="text-center">
                  <v-icon v-if="item.token_saved" color="green"
                    >mdi-check-circle</v-icon
                  >
                  <v-icon v-else color="gray">mdi-close-circle</v-icon>
                </td>
                <td class="text-center">
                  <div v-if="item.dataset_level==='level2'">
                    <v-icon v-if="item.trusted" color="#0af215">mdi-check</v-icon>
                    <v-icon v-else color="#f2190a">mdi-close</v-icon>
                  </div>
                  <div v-else>
                    Does not apply
                  </div>
                </td>
                <td>
                  <span v-if="item.dataset_level==='level1'">Remote query</span>
                  <span v-else>Federated query</span>
                </td>
                <td>
                  <v-menu transition="slide-x-transition" bottom right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on"
                        >mdi-dots-vertical</v-icon
                      >
                    </template>
                    <v-list>
                      <v-list-item @click="changeDatasetPrivacy(item)" v-if="item.dataset_level==='level2'">
                        <v-list-item-icon>
                          <v-icon color="red" v-if="item.trusted"
                            >mdi-shield-lock-outline</v-icon
                          >
                          <v-icon color="green" v-else
                            >mdi-shield-lock-open</v-icon
                          >
                        </v-list-item-icon>
                        <v-list-item-title>
                          <span v-if="item.trusted" color="#f2190a"
                            >Untrust</span
                          >
                          <span v-else color="#0af215">Trust</span>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="track(item.dataset_id)">
                        <v-list-item-icon>
                          <v-icon>mdi-database-sync</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title> Refresh Dataset </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-if="item.catalogue"
                        @click="openEditCatalogue(item)"
                      >
                        <v-list-item-icon>
                          <v-icon>mdi-book-open-variant</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Show Catalogue</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-else @click="openAddCatalogue(item)">
                        <v-list-item-icon>
                          <v-icon>mdi-book-open-variant</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Add Catalogue</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="updateToken(item)">
                        <v-list-item-icon>
                          <v-icon>mdi-key-arrow-right</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Update Token</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="item.auto_approve_dars && webHook.length===0" @click="manageAutoApprove(false, item)">
                        <v-list-item-icon>
                          <v-icon>mdi-autorenew-off</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Disable Automatic DAR Approval</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-else-if="!item.auto_approve_dars && webHook.length===0" @click="manageAutoApprove(true, item)">
                        <v-list-item-icon>
                          <v-icon>mdi-autorenew</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Enable Automatic DAR Approval</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="deleteDts(item)">
                        <v-list-item-icon>
                          <v-icon color="error">mdi-database-minus</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title class="delete-option"
                          >Delete</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
            <template v-slot:footer>
              <v-btn class="mt-5" text color="primary" small @click="newDataset"
                ><v-icon>mdi-plus</v-icon> Add a new Dataset</v-btn
              >
            </template>
          </v-data-table>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="cancel" @click="close">Close</v-btn>
      </v-card-actions>
    </v-card>
    <v-alert class="mb-0" dense type="success" :value="alert"
      >Dataset UUID copied!</v-alert
    >
    <AddCatalogueModal
      :openModal="addCatalogue"
      :rules="rules"
      :trustedDataset="trustedDataset"
      v-if="addCatalogue"
      @close-add-catalogue="closeAddCatalogue"
      @saveCatalogue="saveAddCatalogue"
    />
    <EditCatalogueModal
      :openModal="editCat"
      :rules="rules"
      :trustedDataset="trustedDataset"
      v-if="editCat"
      @close-edit-cat="closeEditCatalogue"
      :catalogue="targetCat"
      @save-edit="saveUpdateCatalogue"
    />
    <EditToken
      :openModal="tokenModal"
      v-if="tokenModal"
      @close="closeEditToken"
      @save-token="saveToken"
    />

  </v-dialog>
</template>
<script>
import AddCatalogueModal from "@/components/dialogs/AddCatalogueModal.vue";
import EditToken from "@/components/dialogs/EditToken.vue";
import EditCatalogueModal from "./EditCatalogueModal.vue";
export default {
  name: "ShowDatasetsModal",
  components: { AddCatalogueModal, EditCatalogueModal, EditToken },
  props: {
    openModal: Boolean,
    datasourceId: Number,
    datasets: Array,
    rules: Object,
    datasetPagination: Object,
    datasetTotal: Number,
    webHook: Array,
  },
  data() {
    return {
      datasetList: [],
      alert: false,
      datasetsModal: false,
      addCatalogue: false,
      editCat: false,
      targetCat: {},
      idTarget: null,
      trustedDataset: null,
      datasetHeaders: [
        { text: "ID", value: "dataset_id" },
        { text: "UUID", value: "dataset_uuid" },
        { text: "Title", value: "title" },
        { text: "Schema", value: "schema_name" },
        { text: "Catalogue", value: "catalogue" },
        { text:"FAIR Token", value:"token_saved"},
        { text: "Trusted", value: "trusted" },
        { text: "Access Level", value: "dataset_level" },
        { text: "Actions", value: "actions" },
      ],
      pagination: this.datasetPagination,
      totalItems:this.datasetTotal,
      targetDataset: {},
      tokenModal: false,
    };
  },

  created() {
    this.datasetList = this.datasets;
    this.datasetsModal = this.openModal;
  },

  methods: {
    copyUuid(uuid) {
      navigator.clipboard.writeText(uuid).then(() => {
        this.alert = true;
        setTimeout(() => {
          this.alert = false;
        }, 2000);
      });
    },
    openAddCatalogue(dataset) {
      this.idTarget = dataset.dataset_id;
      this.trustedDataset = dataset.trusted;
      this.addCatalogue = true;
    },
    openEditCatalogue(dataset) {
      const copy = { ...dataset.catalogue };
      this.idTarget = dataset.dataset_id;
      this.targetCat = copy;
      this.trustedDataset = dataset.trusted;
      this.editCat = true;
    },
    onPageChange(page) {
      this.pagination.page = page;
      this.refreshDatasets();
    },
    onItemsPerPageChange(rowsPerPage) {
      this.pagination.rowsPerPage = rowsPerPage;
      this.refreshDatasets();
    },
    deleteDts(dts) {
      this.$emit("delete", dts, this.datasourceId);
    },
    close() {
      this.datasetsModal = false;
      this.$emit("close-show-dts", this.datasetsModal);
    },
    closeAddCatalogue(val) {
      this.addCatalogue = val;
    },
    closeEditCatalogue(val) {
      this.editCat = val;
    },
    closeEditToken() {
      this.tokenModal = false;
    },
    saveToken(token) {
      this.$emit("update-token", this.targetDataset, this.datasourceId,  token);
      this.tokenModal = false;
    },
    saveAddCatalogue(catalogue) {
      this.$emit("newCatalogue", catalogue, this.idTarget, this.datasourceId);
      this.addCatalogue = false;
    },
    saveUpdateCatalogue(updatedCatalogue) {
      this.$emit(
        "update-catalogue",
        updatedCatalogue,
        this.idTarget,
        this.datasourceId
      );
      this.editCat = false;
    },
    changeDatasetPrivacy(dataset) {
      this.$emit("dts-privacy", dataset, this.datasourceId);
    },
    manageAutoApprove(val, dataset) {
      this.$emit("manage-auto-approve", val, dataset, this.datasourceId);
    },
    updateToken(dataset) {
      this.targetDataset = dataset;
      this.tokenModal = true;
    },
    track(id) {
      this.$emit("track-db", id);
    },
    newDataset() {
      this.$emit("new-dataset", this.datasourceId);
    },
    refreshDatasets() {
      this.$emit("refresh", this.datasourceId, this.pagination);
    },
  },
};
</script>
<style scoped>
.datasets-container {
  overflow-y: auto;
  max-height: 500px;
}
.copy-icon {
  cursor: pointer;
}
.no-datasets {
  text-align: center;
  margin-top: 10px;
}
</style>
