import { render, staticRenderFns } from "./EditToken.vue?vue&type=template&id=20cf28aa"
import script from "./EditToken.vue?vue&type=script&lang=js"
export * from "./EditToken.vue?vue&type=script&lang=js"
import style0 from "./EditToken.vue?vue&type=style&index=0&id=20cf28aa&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VDialog,VDivider,VForm,VIcon,VMenu,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/usr/src/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('20cf28aa')) {
      api.createRecord('20cf28aa', component.options)
    } else {
      api.reload('20cf28aa', component.options)
    }
    module.hot.accept("./EditToken.vue?vue&type=template&id=20cf28aa", function () {
      api.rerender('20cf28aa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/dialogs/EditToken.vue"
export default component.exports